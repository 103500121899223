.cont {
    position: absolute;
    
    animation: twinkle 5s infinite;
}

@keyframes twinkle {
    5% {
        opacity: 0
    }

    15% {
        opacity: 1
    }

}