


.cont {
  font-family: 'Brawler';
  position: absolute;
  top: 160px;
  left: 400px;
  width: 50%;
  color: #fff;
}

.intro {
  position: absolute;
  top: 20px;
  left: -47px;
}

.cont span {
  border-bottom: 1px solid var(--main-color);
  padding-bottom: 2px;
}

.proj p {
  position: relative;
  top: 5px;
}

.proj a {
  color: #fff;
}

.proj a:hover {
  text-decoration: none;
}

p:first-child {
  font-style: italic;
}

.proj {
  width: 90%;
  height: 100%;
  margin: 30px;
}

p:first-child {
  font-style: italic;
  letter-spacing: 0px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  text-decoration: none;
  position: relative;
  padding: 0px 1px;
  overflow: hidden;
  vertical-align: bottom;
  transition: color .1s ease;
}


p:first-child::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  transform: translateY(100%);
  width: 100%;
  height: 100%;
  background: var(--main-color);
  transition: transform .1s ease-out;
}

p:first-child:hover { 
  color: #000; 
  text-decoration: none;
}

p:first-child:hover::before {
  transform: translateY(0);
  transition: transform .1s ease;
}

