@import url('https://fonts.googleapis.com/css2?family=Brawler:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

:root {
  --main-color: #ffdf00;
  background-color: #3a3a4b;
 
}

::selection {
  background: #fff5be; /* WebKit/Blink Browsers */
  color: #000
}
::-moz-selection {
  background: #fff5be; /* Gecko Browsers */
  color: #000
}

body {
  font-family: 'Brawler';
  position: relative;
  left: 150px;
  overflow: hidden
}

.sidebar {
  width: 300px;
  top: 100px;
  position: fixed;
  font-family: 'Brawler';
}



.main {
 position: relative;
 top: 50px
}

.title {
  color: #fff;
  display: inline-block;
  overflow: hidden;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  padding: 2px 1px;
  left: 1px;
}

.title::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  transform: translateY(calc(100% - 2px));
  width: 100%;
  height: 100%;
  background: #000;
  transition: transform .1s ease;
}

.title:hover::before {
  transform: translateY(0);
  background: var(--main-color);
  transition: transform .1s ease;
}

.angle {
  margin-top: 5px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bolder;
  color: var(--main-color);
  letter-spacing: 0.5px;
  margin-right: 3px;
}

.pfp {
  width: 70px;
  border-radius: 100px;
  margin-bottom: 30px;
}

.links {
  font-size: 15px;
}

.links a {
  letter-spacing: 0px;
  margin-bottom: 10px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  text-decoration: none;
  line-height: 1;
  position: relative;
  padding: 2px 1px;
  overflow: hidden;
  vertical-align: bottom;
  transition: color .1s ease;
}


.links a::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  transform: translateY(100%);
  width: 100%;
  height: 100%;
  background: var(--main-color);
  transition: transform .1s ease-out;
}

.links a:hover { 
  color: #000; 
  text-decoration: none;
}

.links a:hover::before {
  transform: translateY(0);
  transition: transform .1s ease;
}

.icons {
  display: none;
  position: relative;
  left: 2px;
  bottom: 15px;
}

.icons a {
  font-size: 14px;
  color: black;
  margin-right: 15px;
  transition: .15s ease;
}

.icons a:hover {
  color: var(--main-color);
  transition: .15s ease;
}



.dialog-2{
  height: 20%;
  width: 40%;
  background-color: white;
  position: absolute;
  border-radius: 10%;
  right: 0;
  animation: twinkle 20s infinite;
}

@keyframes twinkle {
  from {
      opacity: 0
  }

  1% {
      opacity: 1
  }

  25% {
    opacity: 1
  }

  26% {
    opacity: 0
  }

  to {
    opacity: 0
  } 

}

.right-point{
width: 0;
height: 0;
border-left: 1vh solid transparent;
border-right: 1vh solid transparent;
border-top: 2vh solid white;
position: absolute;
top: 40%;
right: -15%;
transform: rotate(-50deg);
}

.box{
width: 10vh;
height: 10vh;
/* border: solid black; */
margin: auto;
position: fixed;
left: 100px;
top: 250px;
display: block;
}

.loading {
  position: relative;
  bottom: 40px;
  left: 5px;
  font-size: 47px;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 2s infinite;
  animation: ellipsis steps(4, end) 2s infinite;
  content: " \2026 ";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 40px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 40px;
  }
}

