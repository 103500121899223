@import url('https://fonts.googleapis.com/css2?family=Brawler:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

:root {
  --main-color: #ffdf00;
  color: #fff;
}

.cont span {
  border-bottom: 1px solid var(--main-color);
  padding-bottom: 2px;
}

.cont {
  font-family: 'Brawler';
  position: absolute;
  top: 160px;
  left: 400px;
  width: 40%;
  color: #fff;
}

.cont a {
  font-style: italic;
  letter-spacing: 0px;
  text-decoration: none;
  display: inline-block;
  text-decoration: none;
  position: relative;
  padding: 0px 0px;
  overflow: hidden;
  vertical-align: bottom;
  transition: color .1s ease;
  color: #fff;
}




.cont a::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  transform: translateY(100%);
  width: 100%;
  height: 100%;
  background: var(--main-color);
  transition: transform .1s ease-out;
}

.cont a:hover { 
  text-decoration: none;
  color: #000
}

.cont a:hover::before {
  transform: translateY(0);
  transition: transform .1s ease;
}

