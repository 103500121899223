@import url(https://fonts.googleapis.com/css2?family=Brawler:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Brawler:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Brawler:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Brawler:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Brawler:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Brawler:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: blue;
}


:root {
  --main-color: #ffdf00;
  background-color: #3a3a4b;
 
}

::selection {
  background: #fff5be; /* WebKit/Blink Browsers */
  color: #000
}
::-moz-selection {
  background: #fff5be; /* Gecko Browsers */
  color: #000
}

body {
  font-family: 'Brawler';
  position: relative;
  left: 150px;
  overflow: hidden
}

.sidebar {
  width: 300px;
  top: 100px;
  position: fixed;
  font-family: 'Brawler';
}



.main {
 position: relative;
 top: 50px
}

.title {
  color: #fff;
  display: inline-block;
  overflow: hidden;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  padding: 2px 1px;
  left: 1px;
}

.title::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  -webkit-transform: translateY(calc(100% - 2px));
          transform: translateY(calc(100% - 2px));
  width: 100%;
  height: 100%;
  background: #000;
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease;
  transition: transform .1s ease, -webkit-transform .1s ease;
}

.title:hover::before {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  background: #ffdf00;
  background: var(--main-color);
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease;
  transition: transform .1s ease, -webkit-transform .1s ease;
}

.angle {
  margin-top: 5px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bolder;
  color: #ffdf00;
  color: var(--main-color);
  letter-spacing: 0.5px;
  margin-right: 3px;
}

.pfp {
  width: 70px;
  border-radius: 100px;
  margin-bottom: 30px;
}

.links {
  font-size: 15px;
}

.links a {
  letter-spacing: 0px;
  margin-bottom: 10px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  text-decoration: none;
  line-height: 1;
  position: relative;
  padding: 2px 1px;
  overflow: hidden;
  vertical-align: bottom;
  transition: color .1s ease;
}


.links a::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  width: 100%;
  height: 100%;
  background: #ffdf00;
  background: var(--main-color);
  transition: -webkit-transform .1s ease-out;
  transition: transform .1s ease-out;
  transition: transform .1s ease-out, -webkit-transform .1s ease-out;
}

.links a:hover { 
  color: #000; 
  text-decoration: none;
}

.links a:hover::before {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease;
  transition: transform .1s ease, -webkit-transform .1s ease;
}

.icons {
  display: none;
  position: relative;
  left: 2px;
  bottom: 15px;
}

.icons a {
  font-size: 14px;
  color: black;
  margin-right: 15px;
  transition: .15s ease;
}

.icons a:hover {
  color: #ffdf00;
  color: var(--main-color);
  transition: .15s ease;
}



.dialog-2{
  height: 20%;
  width: 40%;
  background-color: white;
  position: absolute;
  border-radius: 10%;
  right: 0;
  -webkit-animation: twinkle 20s infinite;
          animation: twinkle 20s infinite;
}

@-webkit-keyframes twinkle {
  from {
      opacity: 0
  }

  1% {
      opacity: 1
  }

  25% {
    opacity: 1
  }

  26% {
    opacity: 0
  }

  to {
    opacity: 0
  } 

}

@keyframes twinkle {
  from {
      opacity: 0
  }

  1% {
      opacity: 1
  }

  25% {
    opacity: 1
  }

  26% {
    opacity: 0
  }

  to {
    opacity: 0
  } 

}

.right-point{
width: 0;
height: 0;
border-left: 1vh solid transparent;
border-right: 1vh solid transparent;
border-top: 2vh solid white;
position: absolute;
top: 40%;
right: -15%;
-webkit-transform: rotate(-50deg);
        transform: rotate(-50deg);
}

.box{
width: 10vh;
height: 10vh;
/* border: solid black; */
margin: auto;
position: fixed;
left: 100px;
top: 250px;
display: block;
}

.loading {
  position: relative;
  bottom: 40px;
  left: 5px;
  font-size: 47px;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 2s infinite;
  animation: ellipsis steps(4, end) 2s infinite;
  content: " \2026 ";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 40px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 40px;
  }
}


:root {
  --main-color: #ffdf00;
  color: #fff;
}

.About_cont__13SIb span {
  border-bottom: 1px solid #ffdf00;
  border-bottom: 1px solid var(--main-color);
  padding-bottom: 2px;
}

.About_cont__13SIb {
  font-family: 'Brawler';
  position: absolute;
  top: 160px;
  left: 400px;
  width: 40%;
  color: #fff;
}

.About_cont__13SIb a {
  font-style: italic;
  letter-spacing: 0px;
  text-decoration: none;
  display: inline-block;
  text-decoration: none;
  position: relative;
  padding: 0px 0px;
  overflow: hidden;
  vertical-align: bottom;
  transition: color .1s ease;
  color: #fff;
}




.About_cont__13SIb a::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  width: 100%;
  height: 100%;
  background: #ffdf00;
  background: var(--main-color);
  transition: -webkit-transform .1s ease-out;
  transition: transform .1s ease-out;
  transition: transform .1s ease-out, -webkit-transform .1s ease-out;
}

.About_cont__13SIb a:hover { 
  text-decoration: none;
  color: #000
}

.About_cont__13SIb a:hover::before {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease;
  transition: transform .1s ease, -webkit-transform .1s ease;
}


:root {
  --main-color: #ffdf00;
}

.Skill_cont__2uEnR span {
  border-bottom: 1px solid #ffdf00;
  border-bottom: 1px solid var(--main-color);
  padding-bottom: 2px;
}

.Skill_cont__2uEnR {
  font-family: 'Brawler';
  position: absolute;
  top: 160px;
  left: 400px;
  width: 40%;
  color: #fff;
}

.Skill_cont__2uEnR li {
  list-style-type: "⏤";
  -webkit-padding-start: 1ch;
          padding-inline-start: 1ch;
}

.Skill_cont__2uEnR a {
  font-style: italic;
  letter-spacing: 0px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  text-decoration: none;
  position: relative;
  padding: 0px 1px;
  overflow: hidden;
  vertical-align: bottom;
  transition: color .1s ease;
}


.Skill_cont__2uEnR a::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  width: 100%;
  height: 100%;
  background: #ffdf00;
  background: var(--main-color);
  transition: -webkit-transform .1s ease-out;
  transition: transform .1s ease-out;
  transition: transform .1s ease-out, -webkit-transform .1s ease-out;
}

.Skill_cont__2uEnR a:hover { 
  color: #000; 
  text-decoration: none;
}

.Skill_cont__2uEnR a:hover::before {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease;
  transition: transform .1s ease, -webkit-transform .1s ease;
}





.Project_cont__34ZvR {
  font-family: 'Brawler';
  position: absolute;
  top: 160px;
  left: 400px;
  width: 50%;
  color: #fff;
}

.Project_intro__1wcKt {
  position: absolute;
  top: 20px;
  left: -47px;
}

.Project_cont__34ZvR span {
  border-bottom: 1px solid var(--main-color);
  padding-bottom: 2px;
}

.Project_proj__3nrWR p {
  position: relative;
  top: 5px;
}

.Project_proj__3nrWR a {
  color: #fff;
}

.Project_proj__3nrWR a:hover {
  text-decoration: none;
}

p:first-child {
  font-style: italic;
}

.Project_proj__3nrWR {
  width: 90%;
  height: 100%;
  margin: 30px;
}

p:first-child {
  font-style: italic;
  letter-spacing: 0px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  text-decoration: none;
  position: relative;
  padding: 0px 1px;
  overflow: hidden;
  vertical-align: bottom;
  transition: color .1s ease;
}


p:first-child::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  width: 100%;
  height: 100%;
  background: var(--main-color);
  transition: -webkit-transform .1s ease-out;
  transition: transform .1s ease-out;
  transition: transform .1s ease-out, -webkit-transform .1s ease-out;
}

p:first-child:hover { 
  color: #000; 
  text-decoration: none;
}

p:first-child:hover::before {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease;
  transition: transform .1s ease, -webkit-transform .1s ease;
}


:root {
  --main-color: #ffdf00;
  color: #fff;
}

.Etc_cont__XGOW9 span {
  border-bottom: 1px solid #ffdf00;
  border-bottom: 1px solid var(--main-color);
  padding-bottom: 2px;
}

.Etc_cont__XGOW9 {
  font-family: 'Brawler';
  position: absolute;
  top: 160px;
  left: 400px;
  width: 40%;
  color: #fff;
}

.Etc_cont__XGOW9 li {
  list-style-type: "⏤";
  -webkit-padding-start: 1ch;
          padding-inline-start: 1ch;
}

.Etc_cont__XGOW9 a {
  font-style: italic;
  letter-spacing: 0px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  text-decoration: none;
  position: relative;
  padding: 0px 1px;
  overflow: hidden;
  vertical-align: bottom;
  transition: color .1s ease;
}


.Etc_cont__XGOW9 a::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  width: 100%;
  height: 100%;
  background: #ffdf00;
  background: var(--main-color);
  transition: -webkit-transform .1s ease-out;
  transition: transform .1s ease-out;
  transition: transform .1s ease-out, -webkit-transform .1s ease-out;
}

.Etc_cont__XGOW9 a:hover { 
  color: #000; 
  text-decoration: none;
}

.Etc_cont__XGOW9 a:hover::before {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease;
  transition: transform .1s ease, -webkit-transform .1s ease;
}



.Star_cont__2cDla {
    position: absolute;
    
    -webkit-animation: Star_twinkle__VTNx4 5s infinite;
    
            animation: Star_twinkle__VTNx4 5s infinite;
}

@-webkit-keyframes Star_twinkle__VTNx4 {
    5% {
        opacity: 0
    }

    15% {
        opacity: 1
    }

}

@keyframes Star_twinkle__VTNx4 {
    5% {
        opacity: 0
    }

    15% {
        opacity: 1
    }

}
:root {
  --main-color: #ffdf00;
  color: #fff;
}

.Nav_cont__2NrLl span {
  border-bottom: 1px solid #ffdf00;
  border-bottom: 1px solid var(--main-color);
  padding-bottom: 2px;
}

.Nav_cont__2NrLl {
  font-family: 'Brawler';
  position: absolute;
  top: 150px;
  left: 400px;
  width: 40%;
  color: #fff;
}

.Nav_cont__2NrLl a {
  font-style: italic;
  letter-spacing: 0px;
  text-decoration: none;
  display: inline-block;
  text-decoration: none;
  position: relative;
  transition: color .1s ease;
  color: #fff;
  margin-right: 20px;
  padding-right: 4px;
}

.Nav_active__3BGxk {
  display: none
}

.Nav_cont__2NrLl a::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  width: 100%;
  height: 110%;
  background: #ffdf00;
  background: var(--main-color);
  transition: -webkit-transform .1s ease-out;
  transition: transform .1s ease-out;
  transition: transform .1s ease-out, -webkit-transform .1s ease-out;
}

.Nav_cont__2NrLl a:hover { 
  text-decoration: none;
}

.Nav_cont__2NrLl a:hover::before {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease;
  transition: transform .1s ease, -webkit-transform .1s ease;
}


:root {
  --main-color: #ffdf00;
  color: #fff;
}

.Interests_cont__2UMLJ span {
  border-bottom: 1px solid #ffdf00;
  border-bottom: 1px solid var(--main-color);
  padding-bottom: 2px;
}

.Interests_cont__2UMLJ {
  font-family: 'Brawler';
  position: absolute;
  top: 160px;
  left: 400px;
  width: 40%;
  color: #fff;
}

.Interests_cont__2UMLJ li {
  list-style-type: "⏤";
  -webkit-padding-start: 1ch;
          padding-inline-start: 1ch;
}

.Interests_cont__2UMLJ a {
  font-style: italic;
  letter-spacing: 0px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  text-decoration: none;
  position: relative;
  padding: 0px 1px;
  overflow: hidden;
  vertical-align: bottom;
  transition: color .1s ease;
}


.Interests_cont__2UMLJ a::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  width: 100%;
  height: 100%;
  background: #ffdf00;
  background: var(--main-color);
  transition: -webkit-transform .1s ease-out;
  transition: transform .1s ease-out;
  transition: transform .1s ease-out, -webkit-transform .1s ease-out;
}

.Interests_cont__2UMLJ a:hover { 
  color: #000; 
  text-decoration: none;
}

.Interests_cont__2UMLJ a:hover::before {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease;
  transition: transform .1s ease, -webkit-transform .1s ease;
}


